import { APITypesV1 } from "@cur8/api-client";

export const INITIAL_SELECTED_RANGE: APITypesV1.Range = {
  from: 0.45,
  to: 0.55,
};
export const MINIMUM_SELECTION = 0.01;

export const ECG_COLOR = "#a9a9a9";
export const SSN_COLOR = "#da1a30";
export const FEMORAL_COLOR = "#ff8c00";
export const LEFT_COLOR = "#00429d";
export const RIGHT_COLOR = "#ff8c00";

export enum PlotType {
  EJECTION_TIME,
  PWV,
  HAND_ASYMMETRY,
  FOOT_ASYMMETRY,
}
