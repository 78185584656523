import { Assessment, Patient } from "@cur8/rich-entity";
import { createContext, ReactNode } from "react";
import { useAssessment } from "./lib";

interface AssessmentContextProps {
  assessments: Assessment[];
  patient: Patient;
  selected: Assessment;
  children: ReactNode;
}
type AssessmentContextValue = ReturnType<typeof useAssessment>;

export const Context = createContext<AssessmentContextValue | null>(null);

export function AssessmentContext({
  assessments,
  patient,
  selected,
  children,
}: AssessmentContextProps) {
  const value = useAssessment({ patient, assessments, selected });

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
