import { APITypesV1 } from "@cur8/api-client";
import { Patient, PulseWaveAssessment } from "@cur8/rich-entity";
import { clamp } from "lib/math";
import { useCallback, useMemo, useState } from "react";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import { useAssessmentNav } from "render/pages/AssessmentPage/hooks/useAssessmentNav";
import {
  INITIAL_SELECTED_RANGE,
  MINIMUM_SELECTION,
} from "../../components/shared/constants";
import { PulsewaveType } from "../../lib/types";
import { getRangesForWindow } from "../../lib/utils";

interface RangesProps {
  assessment: PulseWaveAssessment;
  ecgRange: APITypesV1.Range;
  patient: Patient;
}

export function useRanges({ assessment, ecgRange, patient }: RangesProps) {
  const { pulsewaveType } = useAssessmentNav();
  const { createAssessment, selected } = useAssessmentContext();
  const [signalLength] = useState<number>(ecgRange.to - ecgRange.from);
  const [signalRange] = useState<APITypesV1.Range>(ecgRange);
  const [offset] = useState<number>(ecgRange.from);

  const initialRange = (): APITypesV1.Range => {
    const ar = getRangesForWindow(createAssessment, selected, pulsewaveType);
    const unit = "s";
    if (ar) {
      const padding = ar.to - ar.from;
      const from = clamp(ar.from - padding, signalRange.from, signalRange.to);
      const to = clamp(ar.to + padding, signalRange.from, signalRange.to);
      return { from, to, unit };
    }
    let from = offset + signalLength * INITIAL_SELECTED_RANGE.from;
    let to = offset + signalLength * INITIAL_SELECTED_RANGE.to;
    return { from, to, unit };
  };
  const [windowRange, setWindowRange] =
    useState<APITypesV1.Range>(initialRange);
  const windowLength = useMemo(() => {
    return Math.abs(windowRange.to - windowRange.from);
  }, [windowRange]);

  /**
   * Match the window range to the selection range from assessment
   */
  const onPulseWaveTypeChanged = useCallback(
    (pwt: PulsewaveType, lvetiIdx = 0) => {
      const ar = getRangesForWindow(createAssessment, selected, pwt, lvetiIdx);
      if (ar) {
        let padding = ar.to - ar.from;
        if (pwt !== PulsewaveType.lveti) {
          // Have a least .5 seconds padding (except LVETI)
          padding = Math.max(padding, 0.5);
        }
        const from = clamp(ar.from - padding, signalRange.from, signalRange.to);
        const to = clamp(ar.to + padding, signalRange.from, signalRange.to);
        console.debug("onPulseWaveTypeChanged", { from, to, unit: "s" });
        setWindowRange({ from, to, unit: "s" });
      }
    },
    [createAssessment, selected, signalRange.from, signalRange.to]
  );

  /**
   * Convert a time range to window scalars
   */
  const timeToWindowScalar = useCallback(
    (time: APITypesV1.Range): APITypesV1.Range => {
      const from = (time.from - windowRange.from) / windowLength;
      const to = (time.to - windowRange.from) / windowLength;
      return { from, to };
    },
    [windowLength, windowRange]
  );

  /**
   * Scalar to time
   */
  const scalarToTime = useCallback(
    (scalar: number): number => {
      return signalLength * scalar + offset;
    },
    [offset, signalLength]
  );
  /**
   * Scalar to time
   */
  const windowScalarToTime = useCallback(
    (scalar: number): number => {
      return windowLength * scalar + windowRange.from;
    },
    [windowLength, windowRange]
  );

  /**
   * Resize plot window by scroll wheel event
   */
  const windowScroll = useCallback(
    (event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const step = event.deltaY > 0 ? MINIMUM_SELECTION : -MINIMUM_SELECTION;
      const from = clamp(
        windowRange.from + step,
        signalRange.from,
        signalRange.to
      );
      const to = clamp(windowRange.to - step, signalRange.from, signalRange.to);
      setWindowRange({ from, to, unit: "s" });
    },
    [setWindowRange, signalRange, windowRange]
  );

  /**
   * Window range as scalar of signal length
   */
  const windowToScalar = useCallback((): APITypesV1.Range => {
    const from = Math.abs(windowRange.from - offset) / signalLength;
    const to = Math.abs(windowRange.to - offset) / signalLength;
    return { from, to };
  }, [offset, signalLength, windowRange]);

  return {
    offset,
    onPulseWaveTypeChanged,
    scalarToTime,
    signalRange,
    setWindowRange,
    timeToWindowScalar,
    windowLength,
    windowRange,
    windowScalarToTime,
    windowScroll,
    windowToScalar,
  };
}

export type RangeContextValue = ReturnType<typeof useRanges>;
