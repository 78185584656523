import { APITypesV1 } from "@cur8/api-client";
import { ReactNode, useEffect, useRef } from "react";
import { useRangeContext } from "../../../context/RangeContext";
import { GraphData } from "../../../lib/types";
import { DataPlot } from "./components/DataPlot";
import { DataWindow } from "./components/DataWindow";
import { NavGraph } from "./components/NavGraph";
import { Ruler } from "./components/Ruler";
import styles from "./styles.module.sass";

export interface GraphWithRulerProps {
  data: GraphData[];
  diffData: GraphData[];
  plugin?: ReactNode;

  selectionRange?: APITypesV1.Range;
  onSelectionRange: (range: APITypesV1.Range, complete?: boolean) => void;
  rrInterval?: APITypesV1.Range;
  rrIntervalResize?: (
    pos: number,
    dragLeft: boolean,
    complete?: boolean
  ) => void;
}

export type RulerData = {
  distanceMs: number;
  bpm: number | undefined;
  range: APITypesV1.Range;
};

export function GraphWithRuler({
  data,
  diffData,
  plugin,
  selectionRange,
  onSelectionRange,
  rrInterval,
  rrIntervalResize,
}: GraphWithRulerProps) {
  const { windowScroll } = useRangeContext();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = ref.current;
    if (div) {
      div.addEventListener("wheel", windowScroll, { passive: false });
    }

    return () => {
      if (div) {
        div.removeEventListener("wheel", windowScroll);
      }
    };
  }, [windowScroll]);

  if (data.length === 0) {
    console.warn("No signal provided");
    return null;
  }

  return (
    <div
      className={styles.GraphWithRuler}
      data-testid="graph"
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <div className={styles.signal}>
        <div className={styles.grow}>
          <DataWindow>
            <div className={styles.fullSignal} ref={ref}>
              <NavGraph data={data} height={60} width="100%" />
            </div>
          </DataWindow>
        </div>
        <div className={styles.plugin}>{plugin}</div>
      </div>

      <div className={styles.GraphContainer}>
        <Ruler
          selectionRange={selectionRange}
          onSelectionRange={onSelectionRange}
          rrInterval={rrInterval}
          rrIntervalResize={rrIntervalResize}
        >
          <DataPlot grid={{ x: 0.04 }} data={data} height={140} width="100%" />
          <DataPlot
            grid={{ x: 0.04 }}
            data={diffData}
            height={140}
            width="100%"
          />
        </Ruler>
      </div>
    </div>
  );
}
