import { Assessment, AssessmentTypeName, Patient } from "@cur8/rich-entity";
import { URIType } from "@cur8/uri";
import { useCallback, useMemo } from "react";
import BackIcon from "render/assets/nav/back.svg?react";
import { useImmutableScan } from "render/hooks/api/useImmutableScan";
import { PatientSummary } from "./PatientSummary";
import styles from "./styles.module.sass";

interface AssessmentHeaderProps {
  patient: Patient;
  assessment: Assessment;
}

export function AssessmentHeader({
  patient,
  assessment,
}: AssessmentHeaderProps) {
  const scan = useImmutableScan(
    patient.patientId,
    assessment.scanId,
    assessment.scanVersion
  );
  let title = "Unsupported Assessment";
  switch (assessment.itemTypeName) {
    case AssessmentTypeName.Pad:
      title = "PAD Assessment";
      break;
    case AssessmentTypeName.PulseWave:
      title = "Pulse Wave Assessment";
      break;
    case AssessmentTypeName.Thermal:
      title = "Thermal Assessment";
      break;
  }

  const goBack = useCallback(() => {
    window.history.go(-1);
  }, []);

  const info = useMemo(() => {
    if (!scan) {
      return {
        scanId: "",
        version: "",
        recording: "",
      };
    }
    const sURI = scan.sourceUris.length > 0 ? scan.sourceUris[0] : undefined;
    const recordingId =
      sURI?.type === URIType.Recording ? sURI.recordingId : undefined;
    return {
      scanId: scan.id,
      version: scan.version,
      recording: recordingId,
    };
  }, [scan]);

  return (
    <header className={styles.AssessmentHeader}>
      <div className={styles.patientCol} onClick={goBack}>
        <BackIcon />
        <PatientSummary patient={patient} />
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.scanInfo}>
        Scan: {info.scanId}
        <br />
        Version: {info.version}
        <br />
        Recording: {info.recording}
      </div>
    </header>
  );
}
