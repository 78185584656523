import { useNav, useQueryParams } from "@pomle/react-router-paths";
import { useCallback } from "react";
import { Property } from "render/pages/TissuePages/lib/types";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import { PulsewaveType } from "../components/PulseWavePage/lib/types";

export function useAssessmentNav() {
  const nav = {
    assessment: useNav(paths.patient.assessment, query.assessment),
  };

  const [queryParams, setQueryParams] = useQueryParams(query.assessment);

  const params = paths.patient.assessment.parse(document.location.pathname);

  // PAD
  const property = queryParams.property.at(0) || Property.t1BloodVolume;
  const roi = queryParams.roi.at(0);

  // Pulsewave
  const pulsewaveType = queryParams.pulsewaveType.at(0) || PulsewaveType.lveti;

  // Version
  const setVersionId = useCallback(
    (id: string) => {
      nav.assessment.set({
        patientId: params!.patientId,
        assessmentId: params!.assessmentId,
        versionId: id,
      });
    },
    [nav.assessment, params]
  );

  /**
   * PAD Assessment specifics
   */
  const setSelectedRoi = useCallback(
    (id: string | undefined) => {
      setQueryParams({
        roi: id ? [id] : [],
      });
    },
    [setQueryParams]
  );

  const setProperty = useCallback(
    (prop: Property) => {
      setQueryParams({
        property: [prop],
      });
    },
    [setQueryParams]
  );

  /**
   * Pulsewave Assessment specifics
   */
  const setPulsewaveType = useCallback(
    (type: PulsewaveType) => {
      setQueryParams({
        pulsewaveType: [type],
      });
    },
    [setQueryParams]
  );

  return {
    property,
    pulsewaveType,
    roi,
    setProperty,
    setPulsewaveType,
    setSelectedRoi,
    setVersionId,
    versionId: params?.assessmentId,
  };
}
