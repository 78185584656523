import { Slot } from "@cur8/rich-entity";
import { toList } from "@pomle/react-flat-store";
import { PathLink, useNav, useQueryParams } from "@pomle/react-router-paths";
import { DateTime } from "luxon";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { FaExclamationCircle } from "react-icons/fa";
import { PatientIdentifier } from "render/fragments/patient/PatientIdentifier";
import { PatientScanTable } from "render/fragments/scan/PatientScanTable";
import RecordTable from "render/fragments/visit/RecordTable";
import { usePatient } from "render/hooks/api/usePatient";
import { usePatientAssessments } from "render/hooks/api/usePatientAssessments";
import { usePatientImmutableScans } from "render/hooks/api/usePatientImmutableScans";
import { useRoleBasedAccess } from "render/hooks/useRoleBasedAccess";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import { PassedTime } from "render/ui/format/PassedTime";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { Banner } from "render/ui/presentation/Banner";
import { ButtonSet } from "render/ui/trigger/ButtonSet";
import { DropdownMenuButton } from "render/ui/trigger/DropdownMenuButton";
import { DropdownItem } from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import IcnCheckbox from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/icn-checkbox.svg?react";
import IdCard from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/id-card.svg?react";
import NekoLogo from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/neko-logo.svg?react";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import AddNewRecordButton from "./components/AddNewRecordButton/AddNewRecordButton";
import { AssessmentsTable } from "./components/AssessmentsTable";
import PrivateJournalNote from "./components/PrivateJournalNote";
import { ScheduleFollowUpButton } from "./components/ScheduleFollowUpButton";
import Tabs from "./components/Tabs";
import { usePatientPrivateJournalNotes } from "./hooks/usePatientPrivateJournalNotes";
import { usePatientUpcomingSlot } from "./hooks/usePatientUpcomingSlot";
import { usePatientVisitSummaries } from "./hooks/usePatientVisitSummaries";
import styles from "./styles.module.sass";

enum LastUpdateStatus {
  Unknown,
  Never,
}

type LastUpdate = LastUpdateStatus | DateTime;

interface UpcomingAppointmentProps {
  slot: Slot;
}

function UpcomingAppointment({ slot }: UpcomingAppointmentProps) {
  const timeZoneId = slot.room?.site?.timeZoneId;
  return (
    <div>
      <div>{slot.room?.site?.siteName}</div>
      <div>
        {slot.startTime.setZone(timeZoneId).toFormat("HH:mm")} •{" "}
        {slot.startTime.setZone(timeZoneId).toFormat("dd LLLL")}
      </div>
    </div>
  );
}

interface StatusSummaryProps {
  lastUpdate: LastUpdate;
  fallback: ReactNode;
}

function StatusSummary({ lastUpdate, fallback }: StatusSummaryProps) {
  if (lastUpdate === LastUpdateStatus.Unknown) {
    return null;
  }
  if (lastUpdate === LastUpdateStatus.Never) {
    return <>{fallback}</>;
  }

  return (
    <div>
      Updated <PassedTime date={lastUpdate} />
    </div>
  );
}

interface PatientDetailPageProps {
  patientId: string;
}

export function PatientDetailPage({ patientId }: PatientDetailPageProps) {
  const roleBasedAccess = useRoleBasedAccess();
  const patient = usePatient(patientId);
  const immutableScans = usePatientImmutableScans(patientId);

  const { entries: visitEntries } = usePatientVisitSummaries(patientId);

  const upcomingAppointment = usePatientUpcomingSlot(patientId);
  const assessments = usePatientAssessments(patientId);
  const { entries: privateJournalNoteEntries } =
    usePatientPrivateJournalNotes(patientId);

  const visits = useMemo(() => toList(visitEntries), [visitEntries]);

  const [privateJournalNotes, setPrivateJournalNotes] = useState(
    toList(privateJournalNoteEntries)
  );
  useEffect(() => {
    setPrivateJournalNotes(toList(privateJournalNoteEntries));
  }, [privateJournalNoteEntries]);

  const lastDashboardUpdate = useMemo(() => {
    if (!immutableScans) {
      return LastUpdateStatus.Unknown;
    }

    if (immutableScans.history.length === 0) {
      return LastUpdateStatus.Never;
    }

    const immutableScanDate =
      immutableScans.history.at(0)?.at(0)?.timestamp ?? LastUpdateStatus.Never;

    return immutableScanDate;
  }, [immutableScans]);

  const nav = {
    dash: useNav(paths.patient.dashboard),
    edit: useNav(paths.patient.edit),
    createVisitSummary: useNav(paths.patient.visit.create),
    bloodwork: useNav(paths.patient.bloodworkEntry),
    riskAssessment: useNav(paths.patient.riskAssessmentEntry),
    appointment: useNav(paths.patient.appointment.view),
    createAssignment: useNav(paths.patient.assignment.create),
    encyclopedia: useNav(paths.patient.encyclopedia),
    trackedMarkings: useNav(paths.patient.trackedMarkings),
    examinations: useNav(paths.patient.examinations),
    records: useNav(paths.patient.records),
  };

  const [uriParams, setURIParams] = useQueryParams(query.patientDetail);
  const initialTab = uriParams?.tab?.[0] || "Examinations";

  const [activeTab, setActiveTab] = useState<string>(initialTab);
  const handleTabChange = (label: string) => {
    setActiveTab(label);

    setURIParams({
      tab: [label],
    });

    if (label === "Examinations") {
      nav.examinations.on({ patientId });
    } else if (label === "Records") {
      nav.records.on({ patientId });
    }
  };

  useEffect(() => {
    if (uriParams?.tab?.[0] && uriParams.tab[0] !== activeTab) {
      setActiveTab(uriParams.tab[0]);
    }
  }, [uriParams, activeTab]);

  const scanHistory = useMemo(() => {
    if (immutableScans) {
      return immutableScans.history.flatMap((group) => group);
    }
  }, [immutableScans]);

  const [isPrivateJournalNoteActive, setIsPrivateJournalNoteActive] =
    useState(false);

  const [selectedPrivateJournalNote, setSelectedPrivateJournalNote] =
    useState<any>(null);
  const handleAddNote = () => {
    setSelectedPrivateJournalNote(null);
    setIsPrivateJournalNoteActive(true);
  };

  const handleViewPrivateJournalNote = (note: any) => {
    setSelectedPrivateJournalNote(note);
    setIsPrivateJournalNoteActive(true);
  };

  const handleClosePrivateJournalNote = () => {
    setIsPrivateJournalNoteActive(false);
    setSelectedPrivateJournalNote(null);
  };

  const handleNoteAdded = (newNote: any) => {
    setPrivateJournalNotes((prevNotes) => [...prevNotes, newNote]);
  };

  const handleNoteEdited = (editedNote: any) => {
    setPrivateJournalNotes((prevNotes) =>
      prevNotes.map((note) => (note.id === editedNote.id ? editedNote : note))
    );
  };

  const tabs = [
    {
      label: "Examinations",
      content: (
        <>
          <section className={styles.assessments}>
            <header>
              <div>
                <h2>Assessments</h2>
              </div>
            </header>
            {assessments ? (
              assessments.length ? (
                <AssessmentsTable assessments={assessments} />
              ) : (
                <Banner>
                  <p>No assessments</p>
                </Banner>
              )
            ) : (
              <Banner>
                <p>Looking for assessments</p>
              </Banner>
            )}
          </section>
          <section className={styles.scans}>
            <header>
              <div>
                <h2>Examinations</h2>
              </div>
              <div>
                <ButtonSet>
                  <HoverTextButton
                    onClick={nav.trackedMarkings.on({ patientId })}
                  >
                    Tracked Markings
                  </HoverTextButton>
                </ButtonSet>
              </div>
            </header>
            {scanHistory?.length ? (
              <PatientScanTable scans={scanHistory} />
            ) : (
              <Banner>
                <p>No examinations</p>
              </Banner>
            )}
          </section>
        </>
      ),
    },
    {
      label: "Records",
      content: (
        <>
          <section className={`${styles.visits} ${styles.section}`}>
            <header className={styles.recordsHeader}>
              <div className={styles.recordsTitle}>
                <h2>Records</h2>
                {patient?.safeguarding && (
                  <span className={styles.safeguardingBadge}>
                    <span className={styles.safeguardingIcon}>
                      <FaExclamationCircle />
                    </span>{" "}
                    SAFEGUARDING
                  </span>
                )}
              </div>
              <div>
                <AddNewRecordButton
                  onAddNote={handleAddNote}
                  onAddMemberSummary={nav.createVisitSummary.on({ patientId })}
                />
              </div>
            </header>
            {!visitEntries?.length && !privateJournalNoteEntries?.length ? (
              <Banner>
                <p>No records</p>
              </Banner>
            ) : (
              <RecordTable
                visits={visits}
                privateJournalNotes={privateJournalNotes}
                onViewPrivateJournalNote={handleViewPrivateJournalNote}
              />
            )}
          </section>
        </>
      ),
    },
  ];

  return (
    <FramedPage>
      <div className={styles.PatientDetailPage}>
        <section className={styles.summary}>
          <PageHeader
            caption={
              <PatientIdentifier patientId={patientId} patient={patient} />
            }
          >
            <ButtonSet>
              {patient && roleBasedAccess.feature.booking && (
                <ScheduleFollowUpButton
                  patient={patient}
                  upcomingAppointment={upcomingAppointment}
                />
              )}
              <DropdownMenuButton label="Manage..." header="Manage member">
                <DropdownItem onClick={nav.edit.on({ patientId })}>
                  <IdCard />
                  Edit Member
                </DropdownItem>

                <DropdownItem onClick={nav.createAssignment.on({ patientId })}>
                  <IcnCheckbox />
                  Create Assignment
                </DropdownItem>

                <DropdownItem onClick={nav.encyclopedia.on({ patientId })}>
                  <NekoLogo />
                  Data Table
                </DropdownItem>
              </DropdownMenuButton>
            </ButtonSet>
          </PageHeader>

          <div className={styles.items}>
            <PathLink
              className={styles.item}
              data-disabled={
                lastDashboardUpdate === LastUpdateStatus.Unknown ||
                lastDashboardUpdate === LastUpdateStatus.Never
              }
              to={nav.dash.to({ patientId })}
            >
              <div className={styles.primary}>Health Chart</div>
              <StatusSummary
                lastUpdate={LastUpdateStatus.Unknown}
                fallback="No examinations made"
              />
            </PathLink>

            <PathLink
              className={styles.item}
              to={nav.bloodwork.to({ patientId })}
            >
              <div className={styles.primary}>Bloodwork</div>
              <StatusSummary
                lastUpdate={LastUpdateStatus.Unknown}
                fallback="No bloodwork added"
              />
            </PathLink>

            <PathLink
              className={styles.item}
              to={nav.riskAssessment.to({ patientId })}
            >
              <div className={styles.primary}>Risk</div>
              <StatusSummary
                lastUpdate={LastUpdateStatus.Unknown}
                fallback="Not completed"
              />
            </PathLink>

            {upcomingAppointment?.slotId ? (
              <PathLink
                className={styles.item}
                to={nav.appointment.to({
                  patientId,
                  bookingSlotId: upcomingAppointment.slotId,
                })}
              >
                <div className={styles.primary}>Appointment</div>
                <UpcomingAppointment slot={upcomingAppointment} />
              </PathLink>
            ) : (
              <div className={styles.item} data-disabled={true}>
                <div className={styles.primary}>Appointment</div>
                <div>Nothing scheduled yet</div>
              </div>
            )}
          </div>
        </section>
        <Tabs tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />

        {isPrivateJournalNoteActive && (
          <PrivateJournalNote
            active={isPrivateJournalNoteActive}
            patientId={patientId}
            selectedNote={selectedPrivateJournalNote}
            onClose={handleClosePrivateJournalNote}
            onNoteAdded={handleNoteAdded}
            onNoteEdited={handleNoteEdited}
          />
        )}
      </div>
    </FramedPage>
  );
}
