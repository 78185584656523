// File: Tooltip.js
import { CSSProperties, MouseEvent, ReactNode, useState } from "react";
import styles from "./styles.module.sass";

interface TooltipProps {
  children: [ReactNode, ReactNode];
}

interface CSSPositionProperties {
  bottom: number | undefined;
  left: number | undefined;
  right: number | undefined;
  top: number | undefined;
}

function toCSSProperties(pos: CSSPositionProperties): CSSProperties {
  let props = {} as CSSProperties;
  if (pos.bottom) {
    props.bottom = pos.bottom + "px";
  } else {
    props.top = pos.top + "px";
  }
  if (pos.right) {
    props.right = pos.right + "px";
  } else {
    props.left = pos.left + "px";
  }
  return props;
}

export function Tooltip({ children }: TooltipProps) {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<CSSPositionProperties>(
    {} as CSSPositionProperties
  );

  const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.currentTarget.getBoundingClientRect();
    const tooltipHeight = 40; // Approximate tooltip height
    const tooltipWidth = 200; // Approximate tooltip width

    setPosition((pos) => {
      // Default position: below and to the right of trigger
      pos.left = target.left;
      pos.top = target.bottom + 5;
      if (pos.top + tooltipHeight > window.innerHeight) {
        pos.bottom = target.top + 5; // Place above if no space below
        pos.top = undefined;
      }
      if (pos.left + tooltipWidth > window.innerWidth) {
        pos.left = window.innerWidth - tooltipWidth - 10; // Adjust for viewport width
      }
      return pos;
    });
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const [trigger, content] = children;

  return (
    <div
      className={styles.ToolTipTrigger}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {trigger}
      {visible && (
        <div
          className={styles.TooltipContent}
          style={toCSSProperties(position)}
        >
          {content}
        </div>
      )}
    </div>
  );
}
