import styles from "./styles.module.sass";

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  onTabChange: (label: string) => void;
}

function Tabs({ tabs, activeTab, onTabChange }: TabsProps) {
  const handleTabClick = (label: string) => {
    onTabChange(label);
  };

  const activeTabContent = tabs.find((tab) => tab.label === activeTab)?.content;

  return (
    <div className={styles.Tabs}>
      <div className={styles.tabList}>
        {tabs.map((tab) => (
          <button
            key={tab.label}
            className={`${styles.tab} ${
              tab.label === activeTab ? styles.active : ""
            }`}
            onClick={() => handleTabClick(tab.label)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <hr className={styles.separator} />
      <div className={styles.tabContent}>
        {activeTabContent || <p>No content available for this tab.</p>}
      </div>
    </div>
  );
}

export default Tabs;
